.head {
  background-color: #373c46;
  padding: 15px;
  border-radius: 0% 0% 15px 15px;
  box-shadow: 2px 2px 6px black;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.title {
  padding: 0;
  margin: 0;
}
