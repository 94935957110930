.linkIcon {
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 6px black;
  border: 3px rgb(7, 7, 7) solid;
  transition: all 0.5s;
  padding: 3px;
  width: 7vh;
  text-decoration: none;
  position: relative;
}
.scale {
  transform: scale(1.2);
  transition: all 0.4s;
}
img {
  width: 90%;
  height: 6vh;
  border-radius: 5px;
  display: block;
  margin: auto;
}

.container {
  color: black;
  font-size: 1.5vh;
  text-align: center;
}

.container:first-letter {
  text-transform: uppercase;
  font-weight: bold;
  color: firebrick;
}

.iconPanel {
  position: absolute;
  top: 0vh;
  right: 0em;
  width: 3vh;
  margin-top: 2px;
}
.icon:hover {
  transform: scale(1.2);
  transition: all 0.4s;
}

.icon {
  margin-bottom: 1.2vh;
  transform: scale(1);
  transition: all 0.4s;
  width: 3vh;
  height: 3vh;
}
