.section {
  width: 50vw;
  background-color: #373c46;
  border-radius: 5px;
  box-shadow: 2px 2px 6px black;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5px;
  font-size: 2vh;
  margin: 0px 5px;
}

.sectionName {
  margin: 0px 5px;
  padding: 0px 5px;
  margin-bottom: 0px;
}
