.foot {
  background-color: #373c46;
  padding: 0px;
  padding-left: 20px;
  width: 60vw;
  border-radius: 15px 15px 0% 0%;
  box-shadow: 2px 2px 6px black;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5vh;
}

a:link {
  color: aqua;
}
a:visited {
  color: orange;
}
